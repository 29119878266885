export const UserPermission = {
  ResourcesView: 'RESOURCES_VIEW',
  ResourcesCreate: 'RESOURCES_CREATE',
  ResourcesUpdate: 'RESOURCES_UPDATE',
  ResourcesDelete: 'RESOURCES_DELETE',

  ElementaryActivitiesView: 'ELEMENTARY_ACTIVITIES_VIEW',
  ElementaryActivitiesCreate: 'ELEMENTARY_ACTIVITIES_CREATE',
  ElementaryActivitiesUpdate: 'ELEMENTARY_ACTIVITIES_UPDATE',
  ElementaryActivitiesDelete: 'ELEMENTARY_ACTIVITIES_DELETE',

  CompositeActivitiesView: 'COMPOSITE_ACTIVITIES_VIEW',
  CompositeActivitiesCreate: 'COMPOSITE_ACTIVITIES_CREATE',
  CompositeActivitiesUpdate: 'COMPOSITE_ACTIVITIES_UPDATE',
  CompositeActivitiesDelete: 'COMPOSITE_ACTIVITIES_DELETE',

  ProgramsView: 'PROGRAMS_VIEW',

  PlayersView: 'PLAYERS_VIEW',

  CalendarView: 'CALENDAR_VIEW',

  ProgramPlanningView: 'PROGRAM_PLANNING_VIEW',

  TenantSettingsView: 'TENANT_VIEW',
  TenantSettingsUpdate: 'TENANT_UPDATE',
} as const;
