import { SnackbarType } from '@/constants/snackbarType';
import { HttpStatus } from '@/constants/httpStatus';
import { useUserStore } from '@/store/user';

export const useApi = () => {
  const userStore = useUserStore();

  const apiRequest = async <P>(request: () => Promise<P>, key?: string) => {
    const asyncData = await (key
      ? useAsyncData(key, request)
      : useAsyncData(request));

    if (asyncData.error.value?.statusCode === HttpStatus.UNAUTHORIZED) {
      userStore.logout();

      onMounted(() => {
        const snackbar = useSnackbar();
        const { $i18n } = useNuxtApp();

        snackbar.add({
          text: $i18n.t('error.authorizationTokenExpiredLoginAgain'),
          type: SnackbarType.error,
        });
      });
    }

    const cause = asyncData.error.value?.cause;

    return {
      ...asyncData,
      detail:
        cause &&
        typeof cause === 'object' &&
        'body' in cause &&
        cause.body &&
        typeof cause.body === 'object' &&
        'detail' in cause.body
          ? (cause as { body?: { detail?: string } }).body?.detail
          : undefined,
    };
  };

  return { apiRequest };
};
