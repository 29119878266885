<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage
      :transition="{
        onBeforeEnter,
      }"
    />
  </NuxtLayout>
  <NuxtSnackbar
    :success="current.colors.success"
    :warning="current.colors.warning"
    :info="current.colors.info"
    :error="current.colors.error"
    message-class="snackbarMessage"
  />
</template>

<script setup lang="ts">
import '@/assets/scss/main.scss';

const title = 'IIS';
useHead({
  titleTemplate: (t) => (t ? `${t} | ${title}` : title),
});

const { current } = useTheme();
const { current: vuetifyLocale } = useLocale();
const { locale, finalizePendingLocaleChange } = useI18n();

watch(
  locale,
  (val) => {
    if (vuetifyLocale.value !== val) {
      vuetifyLocale.value = val;
    }
  },
  { immediate: true },
);

const onBeforeEnter = async () => {
  await finalizePendingLocaleChange();
};
</script>

<style>
.page-enter-active,
.page-leave-active {
  transition: all 0.2s;
}
.page-enter-from,
.page-leave-to {
  opacity: 0;
  filter: blur(0.5rem);
}
</style>
