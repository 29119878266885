import type { PermissionRoute } from '@/types/global';
import { UserPermission } from '@/constants/userPermission';
import { Routes } from '@/constants/routes';
import { ProtectedPath } from '@/constants/protectedPath';

export const permissionRoutes: PermissionRoute[] = [
  {
    name: `${ProtectedPath.APP}-${Routes.Resources}`,
    permission: UserPermission.ResourcesView,
  },
  {
    name: `${ProtectedPath.APP}-${Routes.ElementaryActivities}`,
    permission: UserPermission.ElementaryActivitiesView,
  },
  {
    name: `${ProtectedPath.APP}-${Routes.CompositeActivities}`,
    permission: UserPermission.CompositeActivitiesView,
  },
  {
    name: `${ProtectedPath.APP}-${Routes.Programs}`,
    permission: UserPermission.ProgramsView,
  },
  {
    name: `${ProtectedPath.APP}-${Routes.Players}`,
    permission: UserPermission.PlayersView,
  },
  {
    name: `${ProtectedPath.APP}-${Routes.Calendar}`,
    permission: UserPermission.CalendarView,
  },
  {
    name: `${ProtectedPath.APP}-${Routes.ProgramPlanning}`,
    permission: UserPermission.ProgramPlanningView,
  },
  {
    name: `${ProtectedPath.APP}-${Routes.TenantSettings}`,
    permission: UserPermission.TenantSettingsView,
  },
];
