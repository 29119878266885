export const Routes = {
  Resources: 'resources',
  ElementaryActivities: 'elementary-activities',
  CompositeActivities: 'composite-activities',
  Programs: 'programs',
  Players: 'players',
  Calendar: 'calendar',
  ProgramPlanning: 'program-planning',
  UserSettings: 'user-settings',
  TenantSettings: 'tenant-settings',
} as const;
